<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary mb-3">
        <div class="container">
            <a class="navbar-brand" @click="$router.push('/')">Galleria Premio Forlano</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link" @click="$router.push('/galleria')">Galleria</a>
                    </li>
                    <li class="nav-item" v-if="loggedUser">
                        <a class="nav-link" @click="$router.push('/portfolio')">Portfolio</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Contatti</a>
                    </li>
                </ul>
                <div class="text-end navbar-nav">
                    <span class="nav-item d-flex align-items-center dropdown" v-if="loggedUser">
                        <img src="../assets/person-circle.svg" width="16" height="16">
                        <a class="nav-link dropdown-toggle" href="#" role="button"
                            data-bs-toggle="dropdown">{{ loggedUser.nickname }}</a>
                        <ul class="dropdown-menu">
                            <li>
                                <a class="dropdown-item" href="#">Gestione profilo</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="#" @click="logout()">Esci</a>
                            </li>
                        </ul>
                    </span>

                    <span class="nav-item d-flex align-items-center" v-if="!loggedUser">
                        <img src="../assets/person-circle.svg" width="16" height="16">
                        <a class="nav-link" @click="openLoginModal()">Accedi</a>
                    </span>
                </div>
            </div>
        </div>
    </nav>
    <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="loginModalLabel">Il tuo account</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row d-flex justify-content-center">
                        <div class="form-group col-12 col-xl-8 col-lg-10">
                            <label class="active" for="username">Nome utente</label>
                            <div class="input-group input-text">
                                <input type="text" data-bs-input class="form-control" id="username"
                                    v-model="utente.username">
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-3">
                        <div class="form-group col-12 col-xl-8 col-lg-10">
                            <label class="active" for="password">Password</label>
                            <div class="input-group mb-3">
                                <input :type="showPassword ? 'text' : 'password'" data-bs-input class="form-control"
                                    id="password" v-model="utente.password">
                                <button class="btn btn-outline-secondary" type="button"
                                    @click="showPassword = !showPassword">
                                    <img :src="imageUrl" width="16" height="16"></button>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-3">
                        <div v-if="loginError" class="alert alert-danger col-12 col-xl-8 col-lg-10" role="alert">
                            Utente non trovato
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
                    <button type="button" class="btn btn-primary" @click="login()">Accedi</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Utente } from "@/models/Utente";
import router from "@/router";
import * as bootstrap from "bootstrap";
import { computed, onMounted, ref } from "vue";

let loggedUser = ref<Utente | null>();
let modal: any;
let utente = ref<Utente>(new Utente());
let showPassword = ref<boolean>(false);
let loginError = ref<boolean>(false);
let imageUrl = computed(() => {
    if (showPassword.value) {
        return new URL("../assets/eye-slash.svg", import.meta.url).href
    }
    else {
        return new URL("../assets/eye.svg", import.meta.url).href
    }
})

onMounted(() => {
    let logged = sessionStorage.getItem('loggedUser');
    if (logged) {
        loggedUser.value = JSON.parse(logged);
    }
    modal = new bootstrap.Modal(document.getElementById("loginModal")!);
})

function openLoginModal() {
    modal.show();
}

async function login() {
    if (utente.value.username == "utente1" && utente.value.password == "123") {
        let utenteLoggato = {
            username: "utente1",
            password: "123",
            nickname: "Picasso"
        }
        sessionStorage.setItem('loggedUser', JSON.stringify(utenteLoggato));
        loggedUser.value = utenteLoggato;
        modal.hide();
    } else {
        loginError.value = true;
    }
    resetUser();
}

function resetUser() {
    utente.value.username = "";
    utente.value.password = "";
}

function logout() {
    loggedUser.value = null;
    sessionStorage.setItem("loggedUser", "");
    router.push('/');
}

</script>